import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var SFSummaryService = /** @class */ (function () {
    function SFSummaryService(http) {
        this.http = http;
    }
    SFSummaryService.prototype.getPricing = function (request) {
        return this.http.post('/dl/data/pricing/simple-for-sf', request, httpOptions);
    };
    SFSummaryService.prototype.recalculatePricing = function (request) {
        return this.http.post('/dl/data/pricing/simple-for-sf-reprice', request, httpOptions);
    };
    SFSummaryService.ngInjectableDef = i0.defineInjectable({ factory: function SFSummaryService_Factory() { return new SFSummaryService(i0.inject(i1.HttpClient)); }, token: SFSummaryService, providedIn: "root" });
    return SFSummaryService;
}());
export { SFSummaryService };
var Reprice = /** @class */ (function () {
    function Reprice() {
    }
    return Reprice;
}());
export { Reprice };
var RepriceRequestVO = /** @class */ (function () {
    function RepriceRequestVO(spId, benefits) {
        this.spId = spId;
        this.benefits = benefits;
    }
    return RepriceRequestVO;
}());
export { RepriceRequestVO };
var ProductMapping = /** @class */ (function () {
    function ProductMapping() {
        this.product = new Map();
        this.populateProducts();
    }
    ProductMapping.prototype.populateProducts = function () {
        this.product.set('SF', [
            new ProductOptionVO('SF_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('SF_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('SF_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
        this.product.set('CE', [
            new ProductOptionVO('CE_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('CE_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('CE_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
        this.product.set('SFF', [
            new ProductOptionVO('SFF_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('SFF_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('SFF_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
        this.product.set('SFFL', [
            new ProductOptionVO('SFFL_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('SFFL_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('SFFL_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
        this.product.set('SFFC', [
            new ProductOptionVO('SFFC_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('SFFC_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('SFFC_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
        this.product.set('SFFCS', [
            new ProductOptionVO('SFFCS_S', 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65', 'Spouse', 1),
            new ProductOptionVO('SFFCS_C', 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65', 'Child', 8),
            new ProductOptionVO('SFFCS_E', '<b>Extended Family</b> Members include additional Spouse or Partners, Children, Guardian Children, Biological Grandchildren, Biological Parents, Parents in-law, Biological Grandparents, Biological Brothers and Biological Sisters of the Main Member only and permanent employees of the Main Member, Spouse or Partner, subject to the maximum number of Extended Family Members', 'Extended', 8)
        ]);
    };
    ProductMapping.prototype.getProduct = function (prod) {
        return this.product.get(prod);
    };
    return ProductMapping;
}());
export { ProductMapping };
var ProductPricingRequestVO = /** @class */ (function () {
    function ProductPricingRequestVO(productCode, version) {
        if (version === void 0) { version = 'DEFAULT'; }
        this.productCode = productCode;
        this.version = version;
    }
    return ProductPricingRequestVO;
}());
export { ProductPricingRequestVO };
var SimplePricingRequestVO = /** @class */ (function () {
    function SimplePricingRequestVO(spId, age, product) {
        this.spId = spId;
        this.age = age;
        this.product = product;
    }
    return SimplePricingRequestVO;
}());
export { SimplePricingRequestVO };
var DependantVO = /** @class */ (function () {
    function DependantVO() {
    }
    return DependantVO;
}());
export { DependantVO };
var CoverOptionsVO = /** @class */ (function () {
    function CoverOptionsVO(status) {
        this.status = status;
    }
    return CoverOptionsVO;
}());
export { CoverOptionsVO };
var ProductOptionVO = /** @class */ (function () {
    function ProductOptionVO(code, desc, rel, max) {
        this.code = code;
        this.description = desc;
        this.rel = rel;
        this.max = max;
    }
    return ProductOptionVO;
}());
export { ProductOptionVO };
var ChangeEvent = /** @class */ (function () {
    function ChangeEvent(rsaId, product) {
        this.rsaId = rsaId;
        this.product = product;
    }
    return ChangeEvent;
}());
export { ChangeEvent };
var ModalData = /** @class */ (function () {
    function ModalData() {
    }
    return ModalData;
}());
export { ModalData };
